<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-05-11 08:41:31
 * @ Description: Fixer's job application page in the user's job dashboard section of the app.
 -->

<template>
    <v-row
        justify="center"
    >
        <!-- Desktop -->
        <v-col
            cols="12"
            v-if="$vuetify.breakpoint.mdAndUp"
            :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1440px;' : 'max-width: 960px;'"
        >
            <v-row>
                <!-- Back Button & Heading -->
                <v-col
                    cols="12"
                    class="os-22-sb"
                >
                    <v-btn
                        icon
                        @click="$router.push('/job/search')"
                        class="mb-1"
                    >
                        <v-icon
                            size="24"
                            color="#2E567A"
                            style="opacity: 0.65;"
                        >
                            {{ mdiArrowLeftThinCircleOutline }}
                        </v-icon>
                    </v-btn>
                    Job Application: <span class="os-22-r">{{ jobToApplyFor.job_name }}</span>
                </v-col>
                <v-col
                    cols="12"
                    class="py-0"
                >
                    <v-divider />
                </v-col>
                <!-- Back Button & Heading -->

                <!-- Application -->
                <v-col
                    cols="6"
                    style="max-height: calc(100vh - 174px); overflow-y: auto;"
                >
                    <v-row>
                        <v-col
                            cols="12"
                            class="os-22-sb"
                        >
                            My Application
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0"
                        >
                            <v-divider />
                        </v-col>

                        <!-- Cover Letter -->
                        <v-col>
                            <general-tip-tap-text-editor-component
                                class="pb-3"
                                :placeholder="'This is your cover letter. Introduce yourself and briefly describe why you would be the best candidate for the position. (Attach your CV - don’t type it here).'"
                                @textChanged="descriptionChanged"
                                :text="jobToApplyFor.applicant_description"
                            />
                        </v-col>
                        <!-- Cover Letter -->

                        <!-- Documents -->
                        <v-col
                            cols="12"
                            class="os-22-sb"
                        >
                            Documents
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0"
                        >
                            <v-divider />
                        </v-col>
                        <v-col
                            cols="12"
                            class="os-13-r"
                            style="color: #8F9BB3;"
                        >
                            Select the documents from your profile that you want to include with your application. <br />
                            Additional documents (i.e. proof of qualifications etc.) can be uploaded below.
                        </v-col>
                        <v-col
                            cols="12"
                        >
                            <general-document-component
                                :documents="applicationDocuments"
                                :documentCheckboxes="documentCheckboxes"
                                :canDeleteDocuments="false"
                                :canEditDocuments="canEditDocuments"
                                :fileType="'other'"
                                @deleteDocument="confirmDeleteDocument"
                                @downloadDocument="downloadDocument"
                                @createDocument="createDocument"
                            />
                        </v-col>
                        <!-- Documents -->

                        <!-- Apply/Cancel Buttons -->
                        <v-col
                            cols="12"
                            class="text-right pt-6 os-13-sb"
                            align-self="center"
                        >
                            <v-btn
                                text
                                class="no-uppercase rounded-lg os-13-sb mr-4"
                                color="#2E567A"
                                @click="$router.push('/job/search')"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                                @click="confirmApplyForJob"
                                :disabled="!jobToApplyFor.applicant_description"
                            >
                                Apply
                            </v-btn>
                        </v-col>
                        <!-- Apply/Cancel Buttons -->
                    </v-row>
                </v-col>
                <!-- Application -->

                <!-- Job Details -->
                <v-col
                    cols="6"
                    style="max-height: calc(100vh - 174px); overflow-y: auto;"
                >
                    <v-card
                        class="rounded-lg"
                    >
                        <v-card-text
                            style="color: black;"
                        >
                            <v-row
                                justify="center"
                                class="pt-3"
                            >
                                <v-col
                                    cols="12"
                                >
                                    <div
                                        style="height: 100px; display: flex; align-items: center; "
                                    >
                                        <v-img
                                            :src="jobToApplyFor.job_banner ? jobToApplyFor.job_banner : '/general/FixerCompanyIcon.png'"
                                            contain
                                            :max-height="jobToApplyFor.job_banner ? '100px' : '50px'"
                                            :max-width="jobToApplyFor.job_banner ? '100px' : '50px'"
                                            class="mx-auto"
                                        />
                                    </div>
                                </v-col>
                                <v-col
                                    cols="12"
                                    style="color: #8F9BB3;"
                                    class="os-10-r text-center py-0"
                                >
                                    {{ jobToApplyFor.company_name }}
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="os-16-sb text-center"
                                >
                                    {{ jobToApplyFor.job_name }}
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="py-0"
                                >
                                    <v-divider />
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="os-12-sb pb-0"
                                >
                                    Details
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="os-12-r"
                                    style="color: #8F9BB3;"
                                    v-html="jobToApplyFor.job_description"
                                    v-if="jobToApplyFor.job_description"
                                />
                                <v-col
                                    cols="12"
                                    class="os-12-r"
                                    style="color: #8F9BB3;"
                                    v-else
                                >
                                    No job description.
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="py-0"
                                >
                                    <v-divider />
                                </v-col>
                                <v-col
                                    cols="4"
                                >
                                    <div
                                        class="os-10-r"
                                        style="color: #8F9BB3;"
                                    >
                                        Industry:
                                    </div>
                                    <div
                                        class="os-12-sb"
                                        style="color: #2E567A;"
                                    >
                                        {{ jobToApplyFor.job_industry_name }}
                                    </div>
                                    <div
                                        class="os-10-r"
                                        style="color: #8F9BB3;"
                                    >
                                        Job Title:
                                    </div>
                                    <div
                                        class="os-12-sb"
                                        style="color: #2E567A;"
                                    >
                                        {{ jobToApplyFor.job_title_name }}
                                    </div>
                                    <!-- <div
                                        class="os-10-r"
                                        style="color: #8F9BB3;"
                                    >
                                        Location:
                                    </div>
                                    <div
                                        class="os-12-sb"
                                        style="color: #2E567A;"
                                    >
                                        {{ jobToApplyFor.job_mode_name }}
                                    </div> -->
                                    <div
                                        class="os-10-r"
                                        style="color: #8F9BB3;"
                                    >
                                        Type:
                                    </div>
                                    <div
                                        class="os-12-sb"
                                        style="color: #2E567A;"
                                    >
                                        {{ jobToApplyFor.job_type_name }}
                                    </div>
                                    <div
                                        class="os-10-r"
                                        style="color: #8F9BB3;"
                                    >
                                        Mode:
                                    </div>
                                    <div
                                        class="os-12-sb text-truncate"
                                        style="color: #2E567A;"
                                    >
                                        {{ jobToApplyFor.job_mode_name }}
                                    </div>
                                </v-col>
                                <v-col
                                    cols="4"
                                    style="position: relative;"
                                >
                                    <div
                                        class="os-10-r"
                                        style="color: #8F9BB3;"
                                    >
                                        Date Listed:
                                    </div>
                                    <div
                                        class="os-12-sb"
                                        style="color: #2E567A;"
                                    >
                                        {{ jobToApplyFor.job_post_date }}
                                    </div>
                                    <div
                                        style="position: absolute; bottom: 12px;"
                                    >
                                        <div
                                            class="os-10-r"
                                            style="color: #8F9BB3;"
                                        >
                                            Budget:
                                        </div>
                                        <div
                                            class="os-12-sb"
                                            style="color: #2E567A;"
                                        >
                                            <span v-if="jobToApplyFor.job_budget_symbol && jobToApplyFor.job_budget">{{ jobToApplyFor.job_budget_symbol }}{{ jobToApplyFor.job_budget }}</span>
                                            <span v-else>No budget</span>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col
                                    cols="4"
                                    style="position: relative;"
                                >
                                    <div
                                        class="os-10-r"
                                        style="color: #8F9BB3;"
                                    >
                                        Closing Date:
                                    </div>
                                    <div
                                        class="os-12-sb"
                                        style="color: #2E567A;"
                                    >
                                        <span v-if="jobToApplyFor.job_end_date">{{ jobToApplyFor.job_end_date }}</span>
                                        <span v-else>-</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <!-- Job Details -->
            </v-row>
        </v-col>
        <!-- Desktop -->

        <!-- Mobile -->
        <v-col
            cols="12"
            v-else-if="$vuetify.breakpoint.smAndDown"
            :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 280px;' : $vuetify.breakpoint.xs ? 'max-width: 360px;' : 'max-width: 500px'"
        >
            <v-row>
                <!-- Heading & Back Button -->
                <v-col
                    cols="12"
                    class="os-22-sb text-center pt-6"
                    style="position: relative;"
                >
                    <v-btn
                        icon
                        @click="$router.push('/job/search')"
                        style="position: absolute; left: 12px;"
                        class="mb-1"
                    >
                        <v-icon
                            size="24"
                            color="#2E567A"
                            style="opacity: 0.65;"
                        >
                            {{ mdiArrowLeftThinCircleOutline }}
                        </v-icon>
                    </v-btn>
                    Job Application
                </v-col>
                <!-- Heading & Back Button -->

                <!-- Job Details -->
                <v-col
                    cols="12"
                >
                    <v-card
                        class="rounded-lg"
                    >
                        <v-card-text
                            style="color: black;"
                        >
                            <v-row
                                justify="center"
                                class="pt-3"
                            >
                                <v-col
                                    cols="12"
                                    class="text-center"
                                >
                                    <div
                                        style="height: 100px; display: flex; align-items: center; justify-content: center;"
                                    >
                                        <v-img
                                            :src="jobToApplyFor.job_banner ? jobToApplyFor.job_banner : '/general/FixerCompanyIcon.png'"
                                            contain
                                            :max-height="jobToApplyFor.job_banner ? '100px' : '50px'"
                                            :max-width="jobToApplyFor.job_banner ? '100px' : '50px'"
                                            class="mx-auto"
                                        />
                                    </div>
                                </v-col>
                                <v-col
                                    cols="12"
                                    style="color: #8F9BB3;"
                                    class="os-10-r text-center py-0"
                                >
                                    {{ jobToApplyFor.company_name }}
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="os-16-sb text-center"
                                >
                                    {{ jobToApplyFor.job_name }}
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="os-12-r"
                                    style="color: #8F9BB3;"
                                    v-html="jobToApplyFor.job_description"
                                    v-if="jobToApplyFor.job_description"
                                />
                                <v-col
                                    cols="12"
                                    class="os-12-r"
                                    style="color: #8F9BB3;"
                                    v-else
                                >
                                    No job description.
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="py-0"
                                >
                                    <v-divider />
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="os-12-sb text-center"
                                    style="color: #2E567A; text-decoration: underline; cursor: pointer'"
                                    @click="$router.push(`/job/${jobToApplyFor.job_id}/view`)"
                                >
                                    More Information
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <!-- Job Details -->

                <!-- Application -->
                <v-col
                    cols="12"
                >
                    <v-row>
                        <v-col
                            cols="12"
                            class="os-22-sb"
                        >
                            My Application
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0"
                        >
                            <v-divider />
                        </v-col>

                        <!-- Cover Letter -->
                        <v-col>
                            <general-tip-tap-text-editor-component
                                class="pb-3"
                                :placeholder="'This is your cover letter. Introduce yourself and briefly describe why you would be the best candidate for the position. (Attach your CV - don’t type it here).'"
                                @textChanged="descriptionChanged"
                                :text="jobToApplyFor.applicant_description"
                            />
                        </v-col>
                        <!-- Cover Letter -->

                        <!-- Documents -->
                        <v-col
                            cols="12"
                            class="os-22-sb"
                        >
                            Documents
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0"
                        >
                            <v-divider />
                        </v-col>
                        <v-col
                            cols="12"
                            class="os-13-r"
                            style="color: #8F9BB3;"
                        >
                            Select the documents from your profile that you want to include with your application. Additional documents (i.e. proof of qualifications etc.) can be uploaded below.
                        </v-col>
                        <v-col
                            cols="12"
                        >
                            <general-document-component
                                :documents="applicationDocuments"
                                :documentCheckboxes="documentCheckboxes"
                                :canDeleteDocuments="false"
                                :canEditDocuments="canEditDocuments"
                                :fileType="'other'"
                                @deleteDocument="confirmDeleteDocument"
                                @downloadDocument="downloadDocument"
                                @createDocument="createDocument"
                            />
                        </v-col>
                        <!-- Documents -->

                        <!-- Apply/Cancel Buttons -->
                        <v-col
                            cols="12"
                            class="text-right os-13-sb"
                            align-self="center"
                        >
                            <v-btn
                                text
                                class="no-uppercase rounded-lg os-13-sb mr-4"
                                color="#2E567A"
                                @click="$router.push('/job/search')"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                                @click="confirmApplyForJob"
                                :disabled="!jobToApplyFor.applicant_description"
                            >
                                Apply
                            </v-btn>
                        </v-col>
                        <!-- Apply/Cancel Buttons -->
                    </v-row>
                </v-col>
                <!-- Application -->
            </v-row>
        </v-col>
        <!-- Mobile -->

        <!-- Confirm Apply Overlay -->
        <work-general-applicant-confirm-apply-overlay-component
            :showApplyOverlayBoolean="showApplyOverlayBoolean"
            :jobToApplyFor="jobToApplyFor"
            @toggleApplyOverlayComponent="toggleApplyOverlayComponent"
        />
        <!-- Confirm Apply Overlay -->

        <!-- Confirm Delete Overlay -->
        <general-confirm-delete-overlay-component
            :showDeleteConfirmationOverlayBoolean="showDeleteConfirmationOverlayBoolean"
            :itemToDeleteType="'Document'"
            @showDeleteConfirmationOverlay="showDeleteConfirmationOverlay"
        />
        <!-- Confirm Delete Overlay -->

        <!-- Payment Overlay -->
        <payment-overlay-component
            :showPaymentOverlayBoolean="showPaymentOverlayBoolean"
            :invoiceData="invoiceData"
            @showPaymentOverlay="showPaymentOverlay"
        />
        <!-- Payment Overlay -->
    </v-row>
</template>
<script>
    import GeneralTipTapTextEditorComponent from '@/components/general/tiptap/GeneralTipTapTextEditorComponent.vue'
    import GeneralDocumentComponent from '@/components/general/document/GeneralDocumentComponent.vue'
    import { mdiArrowLeftThinCircleOutline } from '@mdi/js'
    import { GeneralInvoiceController, WorkJobApplicationDetailController, WorkJobApplicationDocumentController, GeneralProfileDocumentController } from '@/logic/controller/index.controller.js'
    import WorkGeneralApplicantConfirmApplyOverlayComponent from '@/overlays/work/general/applicant/WorkGeneralApplicantConfirmApplyOverlayComponent.vue'
    import GeneralConfirmDeleteOverlayComponent from '@/overlays/general/GeneralConfirmDeleteOverlayComponent.vue'
    import PaymentOverlayComponent from '@/overlays/payment/PaymentOverlayComponent.vue'

    export default {
        name: 'WorkJobActionIndividualApplicationPage',

        components: {
            GeneralTipTapTextEditorComponent,
            GeneralDocumentComponent,
            WorkGeneralApplicantConfirmApplyOverlayComponent,
            GeneralConfirmDeleteOverlayComponent,
            PaymentOverlayComponent
        },

        computed: {
            canEditDocuments () {
                if (this.$store.state.user.userContext === 'individual' && this.jobToApplyFor.job_stage_id === 2) return true
                else return false
            },

            documentCheckboxes () {
                if (this.$store.state.user.userContext === 'individual' && this.jobToApplyFor.job_stage_id === 2) return true
                else return false
            }
        },

        data () {
            return {
                mdiArrowLeftThinCircleOutline,
                jobToApplyFor: {},
                workJobApplicationDetailController: null,
                workJobApplicationDocumentController: null,
                generalProfileDocumentController: null,
                generalInvoiceController: null,
                showApplyOverlayBoolean: false,
                applicationDocuments: [],
                showDeleteConfirmationOverlayBoolean: false,
                documentToDelete: null,
                invoiceData: {
                    item: {},
                    invoice: {}
                },
                showPaymentOverlayBoolean: false
            }
        },

        beforeMount () {
            this.workJobApplicationDetailController = new WorkJobApplicationDetailController()
            this.workJobApplicationDocumentController = new WorkJobApplicationDocumentController()
            this.generalProfileDocumentController = new GeneralProfileDocumentController()
            this.generalInvoiceController = new GeneralInvoiceController()
        },

        async mounted () {
            await this.getJobDetail()
            await this.getApplicationDocuments()
        },

        methods: {
            async getJobDetail () {
                try {
                    this.jobToApplyFor = await this.workJobApplicationDetailController.retrieve(this.$route.params.id, this.$store.state.user.userData.id)
                    
                    if (this.jobToApplyFor.job_post_date) {
                        const removeTime = this.jobToApplyFor.job_post_date.split(' ')[0]
                        const convertDate = new Date(removeTime)
                        const splitConvertedDate = convertDate.toString().split(' ')
                        this.jobToApplyFor.job_post_date = splitConvertedDate[2] + ' ' + splitConvertedDate[1] + ' ' + splitConvertedDate[3]
                    }

                    if (this.jobToApplyFor.job_end_date) {
                        const removeTime = this.jobToApplyFor.job_end_date.split(' ')[0]
                        const convertDate = new Date(removeTime)
                        const splitConvertedDate = convertDate.toString().split(' ')
                        this.jobToApplyFor.job_end_date = splitConvertedDate[2] + ' ' + splitConvertedDate[1] + ' ' + splitConvertedDate[3]
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getApplicationDocuments() {
                try {
                    this.applicationDocuments = await this.workJobApplicationDocumentController.retrieve(this.jobToApplyFor.job_id, this.$store.state.user.userData.id) // await this.generalProfileDocumentController.retrieve()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            confirmApplyForJob () {
                this.toggleApplyOverlayComponent(true)
            },

            toggleApplyOverlayComponent (value, confirmApplication) {
                this.showApplyOverlayBoolean = value

                if (confirmApplication) this.applyForJob(this.jobToApplyFor)
            },

            async applyForJob (job) {
                try {
                    if (this.jobToApplyFor.applicant_description === '<p></p>') this.jobToApplyFor.applicant_description = null
                    job.applicant_id = this.$store.state.user.userData.id

                    await this.workJobApplicationDetailController.upsert(job)

                    if (this.applicationDocuments && this.applicationDocuments.length > 0) {
                        for (let index = 0; index < this.applicationDocuments.length; index++) {
                            const document = this.applicationDocuments[index]
                            if (document.isApplicationDoc) await this.workJobApplicationDocumentController.create(document, this.$route.params.id, this.$store.state.user.userData.id)
                        }
                    }

                    this.$store.commit('setSnackbar', {
                        text: 'Applied successfully',
                        show: true,
                        color: '#069B34'
                    })

                    this.jobToApplyFor = {}

                    this.$router.push('/job/applications')

                    window.gtag('event', 'job_application')
                } catch (error) {
                    if (error.errorCode) {
                        this.invoiceData = error.invoiceData
                        this.showPaymentOverlay(true)
                    }
                    else {
                        this.$store.commit('setSnackbar', {
                            text: error,
                            show: true
                        })
                    }
                }
            },

            showPaymentOverlay (value, confirmApply, selectedCard) {
                this.showPaymentOverlayBoolean = value

                if (confirmApply) this.payInvoice(this.invoiceData, selectedCard)
            },

            async payInvoice (invoice, card) {
                try {
                    await this.generalInvoiceController.pay(invoice.invoice.id, card.card_id)
                    await this.applyForJob(this.jobToApplyFor)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            descriptionChanged (text) {
                this.jobToApplyFor.applicant_description = text
                if (this.jobToApplyFor.applicant_description === '<p></p>') this.jobToApplyFor.applicant_description = null
            },

            async createDocument (document) {
                try {
                    const response = await this.generalProfileDocumentController.create(document)

                    if (response && response.length > 0) {
                        if (this.applicationDocuments && this.applicationDocuments.length > 0) {
                            for (let index = 0; index < response.length; index++) {
                                const responseDocument = response[index]
                                const documentIndex = this.applicationDocuments.findIndex(documentToFind => documentToFind.fullpath === responseDocument.fullpath)
                                if (documentIndex < 0) this.applicationDocuments.push(responseDocument)
                            }
                        } else this.applicationDocuments = response
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async confirmDeleteDocument (document) {
                this.documentToDelete = document
                this.showDeleteConfirmationOverlay(true)
            },

            async deleteDocument (document) {
                try {
                    this.applicationDocuments = await this.generalProfileDocumentController.delete([document])

                    this.$store.commit('setSnackbar', {
                        text: 'Deleted successfully',
                        show: true,
                        color: '#069B34'
                    })

                    this.documentToDelete = null
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async downloadDocument (documentToDownload) {
                try {
                    const downloadResponse = await this.generalProfileDocumentController.download(documentToDownload)

                    var data = new Blob([downloadResponse])
                    if (typeof window.navigator.msSaveBlob === 'function') {
                        window.navigator.msSaveBlob(data, documentToDownload.filename)
                    } else {
                        var blob = data
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = documentToDownload.filename

                        document.body.appendChild(link)

                        link.click()
                    }

                    this.$store.commit('setSnackbar', {
                        text: 'Downloaded successfully',
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            showDeleteConfirmationOverlay (value, confirmDelete) {
                this.showDeleteConfirmationOverlayBoolean = value

                if (confirmDelete) this.deleteDocument(this.documentToDelete)
            }
        }
    }
</script>
